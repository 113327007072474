import { Card } from 'flowbite-react';
import React from 'react';

interface Label {
  text: string;
  value?: string | number;
}

interface CardWithProgressProps {
  title: string;
  labels: Label[];
  date?: string;
  dropdownOptions?: string[];
  backgroundColor?: 'teal' | 'gray';
  percentage?: number;
  isEmptyState?: boolean;
  onDropdownChange?: (value: string) => void;
  disableDropdown?: boolean;
}

const CardWithProgress: React.FC<CardWithProgressProps> = ({
  title,
  labels,
  date,
  dropdownOptions,
  percentage = 0,
  backgroundColor = 'gray',
  isEmptyState = false,
  onDropdownChange,
  disableDropdown = false,
}) => {
  const progressBarBg = backgroundColor === 'teal' ? 'bg-teal-400' : 'bg-gray-200';

  const labelColors = [
    isEmptyState ? 'bg-gray-200' : 'bg-primary-600',
    isEmptyState ? 'bg-gray-200' : backgroundColor === 'teal' ? 'bg-teal-400' : 'bg-gray-200',
  ];

  return (
    <Card
      theme={{
        root: {
          children: 'px-3 py-4',
        },
      }}
    >
      <div className="flex flex-col gap-5 w-full py-1">
        <div className="flex justify-between items-start">
          <div>
            <h5 className="text-base font-semibold text-gray-900">{title}</h5>
            {date && <p className="text-xs text-gray-500">{date}</p>}
          </div>
          {dropdownOptions && (
            <select
              className="border-gray-300 text-sm font-normal rounded-lg"
              onChange={(e) => onDropdownChange?.(e.target.value)}
              disabled={disableDropdown}
            >
              {dropdownOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </div>
        <div
          className={`min-w-[256px] ${
            isEmptyState ? 'bg-gray-200' : progressBarBg
          } rounded-md h-7 overflow-hidden`}
        >
          {!isEmptyState && (
            <div className="bg-primary-600 h-7" style={{ width: `${percentage * 100}%` }}></div>
          )}
        </div>

        <div className=" border-t border-gray-300 mb-[11px] "></div>

        <div className="flex flex-wrap justify-center items-center gap-6 text-xs mb-3 ">
          {labels.map((x, index) => {
            return (
              <div key={index} className="flex items-center space-x-1 ">
                <div className={`h-2 w-2 rounded-full ${labelColors[index]}`}></div>
                <span className="text-gray-500">{x.text}</span>
                {!isEmptyState && (
                  <>
                    <span className="text-gray-500">:</span>
                    <span className="text-gray-900 font-semibold">{x.value}</span>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default CardWithProgress;
