import { Card, Badge, Spinner, ToggleSwitch, Tooltip, Tabs, Dropdown } from 'flowbite-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PieChart } from '../../../components/charts';
import { ClientBalanceDonutChart } from '../../../components/charts/ClientBalanceDonutChart';
import { Checklist } from '../../../components/Checklist/checklist';
import ClientCard from '../../../components/ClientInfo/ClientInfo';
import CardWithProgress from '../../../components/ProgressBar/ProgressBar';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { useGlobalState } from '../../../context/AppContext';
import { GetClientDashboardQuery, useGetClientDashboardQuery } from '../../../graphql/generated';
import { useFlyoutNavigate } from '../../../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { isClient } from '../../../utils';

import { CreditCardLoans } from './loans/CreditCardLoans';
import { FederalLoans } from './loans/FederalLoans';
import { PrivateLoans } from './loans/PrivateLoans';
import { Reports } from './reports/Reports';

const RepaymentPlanBadge = ({ plan }: { plan: string }) => {
  let color: string | undefined;

  switch (plan?.toLocaleLowerCase()) {
    case 'revised pay as you earn':
      color = 'red';
      plan = 'repaye';
      break;

    case 'save':
      color = 'indigo';
      break;

    case 'pay as you earn':
      color = 'orange';
      plan = 'PAYE';
      break;

    case 'income based':
      color = 'purple';
      plan = 'IBR';
      break;

    case 'income contingent':
      color = 'pink';
      plan = 'ICR';
      break;

    case 'standard':
    case 'extended':
    case 'consolidated':
      color = 'teal';
      break;

    case 'graduated':
      color = undefined;
      break;
  }

  return (
    <Badge color={color} className="inline mr-1">
      {plan?.toUpperCase()}
    </Badge>
  );
};

const MOCK_DATA_PVI = [
  {
    type: 'Principal',
    amount: 214125,
  },
  {
    type: 'Interest',
    amount: 25333,
  },
];

const MOCK_DATA_DEBT_BY_TYPE = [
  {
    __typename: 'LoanWithType',
    type: 'Direct Stafford Unubsidized',
    amount: 154080,
  },
  {
    __typename: 'LoanWithType',
    type: 'Direct Plus Graduate',
    amount: 54053,
  },
  {
    __typename: 'LoanWithType',
    type: 'Other Loan Types',
    amount: 15788,
  },
];

const HeaderItem = ({
  title,
  tooltip,
  text,
}: {
  title: ReactNode;
  text?: ReactNode;
  tooltip?: ReactNode;
}) => {
  return (
    <div className="flex grow flex-col gap-4 max-w-[20%]">
      <span className="text-sm font-medium text-gray-500">
        {tooltip ? (
          <Tooltip content={tooltip}>
            <div className="flex items-center">
              {title}&nbsp;
              {<HiOutlineExclamationCircle className="inline" />}
            </div>
          </Tooltip>
        ) : (
          title
        )}
      </span>
      <div className={'flex gap-x-2 items-center text-2xl font-medium'}>
        <span className={text === null ? 'text-gray-500' : 'text-primary-800'}>
          {text === undefined ? '' : text == null ? 'n/a' : text}
        </span>
      </div>
    </div>
  );
};

const ClientDashboard = ({
  clientId,
  data,
}: {
  clientId: number;
  data: GetClientDashboardQuery | undefined;
}) => {
  const { t } = useFinologyTranslation();
  const params = useParams();
  const location = useLocation();
  const { navigate: flyoutNavigate } = useFlyoutNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [showPrivateChart, setShowPrivateChart] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(() => {
    return !data?.clientDashboardDetails?.maxIdrMonthsInRepayment ? 'PSLF' : 'IDR';
  });

  const client = data?.client;

  useEffect(() => {
    if (data) {
      const onlyPrivateData =
        !data.clientDashboardDetails?.loansPerType &&
        !!data.clientDashboardDetails?.privateLoansPerType;

      setShowPrivateChart(onlyPrivateData);
    }
  }, [data]);

  useEffect(() => {
    if (!data?.clientDashboardDetails?.maxIdrMonthsInRepayment) {
      setSelectedProgram('PSLF');
    }
  }, [data?.clientDashboardDetails?.maxIdrMonthsInRepayment]);

  const handleCardSwitch = () => {
    setShowPrivateChart(!showPrivateChart);
  };

  const handleProgramChange = (program: string) => {
    setSelectedProgram(program);
  };

  if (!client) {
    return <></>;
  }

  const { user } = useGlobalState();

  const principalVsInterestChartData = useMemo(() => {
    return data?.clientDashboardDetails && data?.clientDashboardDetails.numberOfFederalLoans > 0
      ? [
          {
            type: 'Principal',
            amount: data.clientDashboardDetails!.principalLoansSum,
          },
          {
            type: 'Interest',
            amount: data.clientDashboardDetails!.interestLoansSum,
          },
        ]
      : undefined;
  }, [data?.clientDashboardDetails]);

  const shouldLockScreen = isClient(user) && !data?.clientDashboardDetails?.hasLoans;

  const hasPrivateAndFederalDebt =
    data?.clientDashboardDetails?.privateLoansPerType && data?.clientDashboardDetails?.loansPerType;

  const principalVsInterestPercentage = useMemo(() => {
    const principalSum = data?.clientDashboardDetails?.principalLoansSum || 0;
    const interestSum = data?.clientDashboardDetails?.interestLoansSum || 0;
    const total = principalSum + interestSum;

    return (principalSum / total) * 100;
  }, [
    data?.clientDashboardDetails?.principalLoansSum,
    data?.clientDashboardDetails?.interestLoansSum,
  ]);

  const loansPerType = data?.clientDashboardDetails?.loansPerType;

  const tableData = useMemo(() => {
    return {
      federalLoans: {
        outstandingBalance: data?.clientDashboardDetails?.federalTotalLoanAmount || 0,
        monthlyPayment: data?.clientDashboardDetails?.federalLoansMonthlyPayment || 0,
        weightedRate: data?.clientDashboardDetails?.averageFederalInterestRate || 0,
      },
      privateLoans: {
        outstandingBalance: data?.clientDashboardDetails?.privateLoansSum || 0,
        monthlyPayment: data?.clientDashboardDetails?.privateLoansMonthlyPayment || 0,
        weightedRate: data?.clientDashboardDetails?.averagePrivateInterestRate || 0,
      },
      creditCardLoans: {
        outstandingBalance: data?.clientDashboardDetails?.creditCardLoansSum || 0,
        monthlyPayment: data?.clientDashboardDetails?.creditCardLoansMonthlyPayment || 0,
        weightedRate: (data?.clientDashboardDetails?.creditCardLoansWeightedAverageRate || 0) / 100,
      },
      total: {
        outstandingBalance:
          data?.clientDashboardDetails?.federalTotalLoanAmount +
            data?.clientDashboardDetails?.privateLoansSum +
            data?.clientDashboardDetails?.creditCardLoansSum || 0,
        monthlyPayment:
          data?.clientDashboardDetails?.federalLoansMonthlyPayment +
            data?.clientDashboardDetails?.privateLoansMonthlyPayment +
            data?.clientDashboardDetails?.creditCardLoansMonthlyPayment || 0,
        weightedRate:
          (() => {
            const federal = data?.clientDashboardDetails?.averageFederalInterestRate || 0;
            const privateLoansWeightedAverage =
              data?.clientDashboardDetails?.averagePrivateInterestRate || 0;
            const creditCard =
              (data?.clientDashboardDetails?.creditCardLoansWeightedAverageRate || 0) / 100;

            const nonZeroCount =
              (federal ? 1 : 0) + (privateLoansWeightedAverage ? 1 : 0) + (creditCard ? 1 : 0);
            if (nonZeroCount === 0) return 0;

            return (federal + privateLoansWeightedAverage + creditCard) / nonZeroCount;
          })() || 0,
      },
    };
  }, [data?.clientDashboardDetails]);

  return (
    <div className="p-4 space-y-6 max-w-[1200px]">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-4">
          <ClientCard
            name={`${client.firstName} ${client.lastName}`}
            role="Client"
            occupation={client.occupation || 'N/A'}
            state={client.stateCode || 'N/A'}
            income={client.salary}
            incomeTooltip="Client's annual income"
            clientId={clientId}
            spouse={client.spouse}
            maritalStatus={client.maritalStatus}
            taxFilingType={client.taxFilingType}
          />
          <Checklist clientId={clientId} />
        </div>
        <Card
          theme={{
            root: {
              children: 'p-0',
            },
          }}
          className="p-2 min-w-[490px] h-[420px] overflow-y-auto"
        >
          <ClientBalanceDonutChart
            federal={data.clientDashboardDetails?.federalTotalLoanAmount}
            private={data.clientDashboardDetails?.privateLoansSum}
            creditCards={data.clientDashboardDetails?.creditCardLoansSum}
            interestBalance={data?.clientDashboardDetails?.interestLoansSum}
            principalBalance={data?.clientDashboardDetails?.principalLoansSum}
            isEmptyState={!data.clientDashboardDetails?.hasLoans}
            loansPerType={loansPerType}
          />
        </Card>
        <div className="flex flex-col gap-4 w-full">
          <CardWithProgress
            title="Federal Loans"
            percentage={principalVsInterestPercentage ? principalVsInterestPercentage / 100 : 0}
            labels={[
              { text: 'Principal', value: principalVsInterestPercentage.toFixed(2) },
              { text: 'Interest', value: (100 - principalVsInterestPercentage).toFixed(2) },
            ]}
            backgroundColor="teal"
            isEmptyState={!data.clientDashboardDetails?.hasLoans}
          />
          <CardWithProgress
            title={`${selectedProgram} Months `}
            date={selectedProgram === 'PSLF' ? '02/02/2020' : '03/15/2021'}
            percentage={
              selectedProgram === 'PSLF'
                ? (Math.max(
                    0,
                    ...(data?.clientDashboardDetails?.pslfCumulativeMatchedMonths || [0])
                  ) || 0) / 120
                : (data?.clientDashboardDetails?.idrMonthsInRepayment || 0) /
                  (data?.clientDashboardDetails?.maxIdrMonthsInRepayment || 1)
            }
            labels={[
              {
                text: 'Certified',
                value:
                  selectedProgram === 'PSLF'
                    ? Math.max(
                        0,
                        ...(data?.clientDashboardDetails?.pslfCumulativeMatchedMonths || [0])
                      )
                    : data?.clientDashboardDetails?.idrMonthsInRepayment || 0,
              },
              {
                text: 'Remaining',
                value:
                  selectedProgram === 'PSLF'
                    ? 120
                    : data?.clientDashboardDetails?.maxIdrMonthsInRepayment,
              },
            ]}
            dropdownOptions={['IDR', 'PSLF']}
            onDropdownChange={handleProgramChange}
            isEmptyState={!data.clientDashboardDetails?.hasLoans}
            disableDropdown={!data?.clientDashboardDetails?.maxIdrMonthsInRepayment}
          />
        </div>
        {/* <div>
          <RecentActivity />
        </div> */}
      </div>

      <div className="gap-2 w-full">
        <div className="mt-8">
          <FinologyTable
            type="no-promise"
            tableHeadingLabel="Total Balances"
            grayZeroValues={true}
            columns={[
              {
                title: 'TYPE',
                dataIndex: 'type',
                key: 'type',
              },
              {
                title: 'OUTSTANDING BALANCE',
                dataIndex: 'outstandingBalance',
                key: 'outstandingBalance',
                render: (value: number) => toDollars(value),
              },
              {
                title: 'TOTAL MONTHLY PAYMENT',
                dataIndex: 'monthlyPayment',
                key: 'monthlyPayment',
                render: (value: number) => toDollars(value),
              },
              {
                title: 'WEIGHTED AVERAGE RATE',
                dataIndex: 'weightedRate',
                key: 'weightedRate',
                render: (value: number) => toPercentage(value),
              },
            ]}
            rows={[
              {
                key: '1',
                type: 'Federal Loans',
                ...tableData.federalLoans,
              },
              {
                key: '2',
                type: 'Private Loans',
                ...tableData.privateLoans,
              },
              {
                key: '3',
                type: 'Credit Card Loans',
                ...tableData.creditCardLoans,
              },
              {
                key: '4',
                type: 'Client Total',
                ...tableData.total,
              },
            ]}
            hideFooter={true}
            emptyText={() => (
              <div className="flex flex-col items-center gap-4">
                <div className="text-gray-500 text-lg font-medium text-center max-w-xl">
                  Please input your loan information or ask your advisor to add loan details, to
                  enhance your Finology account.
                </div>
                <>
                  <Dropdown color="secondaryLight" label={<>Add Loan</>}>
                    <Dropdown.Item
                      onClick={() => {
                        setActiveTab(1);
                        flyoutNavigate('upsert-federal-loan', `clientId=${clientId}`);
                      }}
                    >
                      Federal
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setActiveTab(2);
                        flyoutNavigate('upsert-private-loan', `clientId=${clientId}`);
                      }}
                    >
                      Private
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setActiveTab(2);
                        flyoutNavigate('upsert-credit-card', `clientId=${clientId}`);
                      }}
                    >
                      Credit Card
                    </Dropdown.Item>
                  </Dropdown>
                </>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const LockedScreenMessage = () => (
  <div
    className="absolute top-0 h-full -left-4 flex z-10 justify-center items-center "
    style={{
      width: '102%',
      backgroundColor: 'rgba(77, 77, 77, 0.75)',
    }}
  >
    <div className="text-white text-2xl max-w-xl font-medium">
      Please input your loan information or ask your advisor to add loan details, to enhance your
      Finology account.
    </div>
  </div>
);

const CardWithClient = ({
  data,
  isClient,
  hasLoans,
  title,
  mockData,
  handleChange,
  switchChecked,
  switchLabels,
  emptyStateLabel,
}: any) => {
  if (hasLoans && data)
    return (
      <Card>
        <div className="flex flex-col">
          <div className="flex justify-between">
            {title}
            {handleChange ? (
              <div className="flex gap-x-2 items-center">
                {switchLabels?.pre && <label className="cursor-pointer">{switchLabels?.pre}</label>}
                <ToggleSwitch
                  color="primary"
                  label=""
                  checked={switchChecked}
                  onChange={handleChange}
                />
                {switchLabels?.post && (
                  <label className="cursor-pointer">{switchLabels?.post}</label>
                )}
              </div>
            ) : null}
          </div>

          <PieChart data={data} />
        </div>
      </Card>
    );

  if (isClient && !hasLoans)
    return (
      <Card title={title}>
        <PieChart data={mockData} />
      </Card>
    );

  return (
    <Card title={title}>
      <div className="flex flex-col">
        <div className="flex justify-between">
          {title}
          {handleChange ? (
            <div className="flex gap-x-2 items-center">
              {switchLabels?.pre && <label className="cursor-pointer">{switchLabels?.pre}</label>}
              <ToggleSwitch
                color="primary"
                label=""
                checked={switchChecked}
                onChange={handleChange}
              />
              {switchLabels?.post && <label className="cursor-pointer">{switchLabels?.post}</label>}
            </div>
          ) : null}
        </div>
      </div>
      <div className="h-80 flex items-center justify-center whitespace-nowrap">
        {emptyStateLabel || 'No data to be displayed.'}
      </div>
    </Card>
  );
};

export const ClientDetails = ({ clientId }: { clientId: number }) => {
  const { loading, data } = useGetClientDashboardQuery({
    variables: { clientId: clientId },
    fetchPolicy: 'network-only',
  });
  const { navigate: flyoutNavigate } = useFlyoutNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const { t } = useFinologyTranslation();

  const tabs = [
    {
      title: 'Client Details',
      content: <ClientDashboard clientId={clientId} data={data} />,
      active: activeTab === 0,
    },
    {
      title: 'Federal Loans',
      content: <FederalLoans clientId={clientId} />,
      active: activeTab === 1,
    },
    {
      title: 'Private Loans',
      content: <PrivateLoans clientId={clientId} />,
      active: activeTab === 2,
    },
    {
      title: 'Credit Loans',
      content: <CreditCardLoans clientId={clientId} />,
      active: activeTab === 3,
    },
    {
      title: 'Reports',
      content: <Reports clientId={clientId} />,
      active: activeTab === 4,
    },
  ];

  if (loading) return <Spinner />;

  return (
    <>
      <Tabs
        onActiveTabChange={(index) => setActiveTab(index)}
        theme={{
          base: 'flex flex-col',
          tablist: {
            base: 'mx-[-24px] z-1 px-10 flex text-center flex-wrap border-b border-zinc-300 mt-4 drop-shadow-lg',
          },
          tabpanel: 'p-0',
        }}
        style="underline"
      >
        {tabs.map((tab, index) => (
          <Tabs.Item
            key={index}
            active={tab.active}
            title={<div className="flex items-center justify-center gap-1">{tab.title}</div>}
          >
            {tab.content}
          </Tabs.Item>
        ))}
      </Tabs>
    </>
  );
};
