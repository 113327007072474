import { ApexOptions } from 'apexcharts';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { toShorthand } from '../../util/currency.formatter';

interface ClientBalanceDonutChartProps {
  federal: number;
  private: number;
  creditCards: number;
  principalBalance?: number;
  interestBalance?: number;
  isEmptyState?: boolean;
  loansPerType?: { amount: number; type: string }[] | null;
}

export const ClientBalanceDonutChart = ({
  federal,
  private: privateLoans,
  creditCards,
  principalBalance = 0,
  interestBalance = 0,
  isEmptyState = false,
  loansPerType = [],
}: ClientBalanceDonutChartProps) => {
  const [isFederalSelected, setIsFederalSelected] = useState(false);

  const total = isFederalSelected
    ? principalBalance + interestBalance
    : federal + privateLoans + creditCards;

  const series = isFederalSelected
    ? loansPerType?.map((loan) => loan.amount) || []
    : [federal, privateLoans, creditCards].filter((val) => val > 0);

  const labels = isFederalSelected
    ? loansPerType?.map((loan) => loan.type) || []
    : ['Federal', 'Private', 'Credit Cards'].filter(
        (_, index) => [federal, privateLoans, creditCards][index] > 0
      );

  const colors = isFederalSelected
    ? loansPerType?.map((_, index) => {
        const baseColors = ['#1C64F2', '#16BDCA', '#FDBA8C', '#9061F9', '#E74694', '#31C48D'];
        return baseColors[index % baseColors.length];
      })
    : ['#1C64F2', '#16BDCA', '#FDBA8C'];

  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 25,
              color: '#6B7280',
              fontSize: '16px',
            },
            value: {
              show: true,
              offsetY: -10,
              fontSize: '32px',
              fontWeight: 600,
              color: '#6B7280',
              formatter: function (val: string) {
                return isEmptyState ? '$0' : `$${toShorthand(Number.parseFloat(val))}`;
              },
            },
            total: {
              show: true,
              label: 'Total Loan Amount',
              fontSize: '16px',
              color: '#6B7280',
              formatter: function () {
                return isEmptyState ? '$0' : `$${toShorthand(total, false)}`;
              },
            },
          },
        },
      },
    },
    legend: {
      show: !isEmptyState,
      position: 'bottom',
      formatter: function (seriesName: string, opts) {
        if (isEmptyState) {
          return `${seriesName}: $0`;
        }
        const value = opts.w.globals.series[opts.seriesIndex];
        return `${seriesName}: $${toShorthand(value)}`;
      },
    },
    states: {
      hover: {
        filter: {
          type: isEmptyState ? 'none' : 'lighten',
          value: 0.15,
        },
      },
      active: {
        filter: {
          type: isEmptyState ? 'none' : 'lighten',
          value: 0.15,
        },
      },
    },
    colors: isEmptyState ? ['#E5E7EB'] : colors,
  };

  return (
    <div className="w-full">
      <div className="flex justify-between gap-4 items-center mb-4">
        <h2 className="text-md font-bold">Loan Analytics</h2>
        {!isEmptyState && (
          <div className="flex">
            <span
              className={`px-4 py-1 rounded-md cursor-pointer ${
                !isFederalSelected ? 'bg-blue-100 text-gray-700' : 'text-gray-700'
              }`}
              onClick={() => setIsFederalSelected(false)}
            >
              All
            </span>
            <span
              className={`px-4 py-1 rounded-md cursor-pointer ${
                isFederalSelected ? 'bg-blue-100 text-gray-700' : 'text-gray-700'
              }`}
              onClick={() => setIsFederalSelected(true)}
            >
              Federal
            </span>
          </div>
        )}
      </div>
      <div className="pointer-events-none">
        <ReactApexChart
          options={options}
          series={isEmptyState ? [1] : series}
          type="donut"
          height={340}
        />
      </div>
      {isEmptyState && (
        <div className="border border-gray-200 border-t-1 border-b-0 border-x-0 max-h-[30px]">
          <div className="flex justify-between items-center px-2 pt-4 pb-1">
            <div className="flex items-center gap-2">
              <div className={`h-2 w-2 rounded-full bg-gray-500`}></div>
              <span className="text-gray-500">Federal </span>
            </div>
            <div className="flex items-center gap-2">
              <div className={`h-2 w-2 rounded-full bg-gray-500`}></div>
              <span className="text-gray-500">Private </span>
            </div>
            <div className="flex items-center gap-2">
              <div className={`h-2 w-2 rounded-full bg-gray-500`}></div>
              <span className="text-gray-500">Credit Card</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
