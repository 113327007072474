import dayjs from 'dayjs';
import { Button, Modal } from 'flowbite-react';
import { Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';

import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import {
  CalculatorFederalLoanView,
  CalculatorView,
  LoansToPrioritize,
  MaritalStatus,
  PaydownMethods,
  TaxFilingType,
} from '../../../graphql/generated';
import { convertScenarioJsonToFormData, createInitialValues } from '../utils';

import { ClientInformationForm } from './ClientInformationForm';
import { FederalLoanSummaryForm } from './FederalLoanSummaryForm';
import { PrivateLoanSummaryForm } from './PrivateLoanSummaryForm';
import { Scenarios } from './Scenarios';

export type CalculatorFederalLoanStatusFormValue = {
  effectiveDate?: dayjs.Dayjs | null;
  status?: string | null;
};

export type CalculatorFederalLoanFormValue = {
  graduate: boolean;
  id: number;
  assignedRepaymentPlanType: string;
  principalBalance: number;
  interestBalance: number;
  type?: string | null;
  interestRate: number;
  repaymentPlanType?: string | null;
  eligibleRepaymentPlanTypes: string[];
  loanDate?: dayjs.Dayjs | null;
  repaymentBeginDate?: dayjs.Dayjs | null;
  idrMonthsInRepayment: number;
  teacherLoanForgivenessMonthsOfCredit: number;
  disbursedAmount: number;
  pslfCount: number;
  loanSubsidizedUsageInYears: number;
  statuses: CalculatorFederalLoanStatusFormValue[];
};

export type CalculatorPrivateLoanFormValue = {
  amount: number;
  extraPayout: number;
  interestRate: number;
  monthlyPayment: number;
  name: string;
  startDate?: dayjs.Dayjs | null;
  type?: string;
};

export type CalculatorCreditCardLoanFormValue = {
  minimumPayments: number;
  purchaseApr: number;
  balanceTransferApr: number;
  outstandingBalance: number;
  balanceTransferRate: number;
  monthsToPay: number;
  type: string | null | undefined;
  bankName: string;
  newMonthsToPay?: number;
  newPaymentAmount?: string;
  newBalanceApr?: number;
};

export type CalculatorFormValues = {
  annualIncreaseOfMonthlyExpensesPercentage?: number;
  annualSalaryDeduction?: number;
  monthlyExpenses?: number;
  id: number;
  isSelfEmployed: boolean;
  makeExtraPaymentsWhenCashFlowAllows: boolean;
  leftoverIncomeToUseTowardsLoansType: boolean;
  leftoverIncomeToUseTowardsLoans?: number;
  addedExcessCashFromOtherSources?: number;
  employerMonthlyContribution?: number;
  employerContributionLifetimeMaximum?: number;
  loansToPrioritize: LoansToPrioritize;
  paydownMethod: PaydownMethods;
  isPslfActive: boolean;
  currentSavingTowardsTaxBomb?: number;
  estimatedMonthlySavingTowardsTaxBomb?: number;
  isTeacherForgivnessActive: boolean;
  teacherLoanForgivenessLimit?: number;
  itemizedDeductions?: number;
  spouseItemizedDeductions?: number;
  agiInflation: number;
  maritalStatus: MaritalStatus;
  spouseAgiInflation: number;
  spouseAnnualSalary: number;
  clientSalary: number;
  dependentsNumber: number;
  otherDependentsNumber: number;
  taxFilingType: TaxFilingType;
  pslfAndIdrField: boolean;
  clientAgiProjections: number[];
  spouseAgiProjections: number[];
  plannedChildren: number;
  repaymentStartDate: dayjs.Dayjs;
  privateLoans: CalculatorPrivateLoanFormValue[];
  creditCards: CalculatorCreditCardLoanFormValue[];
  spouseId?: number | null;
  isTimeBasedForgivnessActive: boolean;
  rateOfReturnOnTaxSavingsBomb: number;
  isProjectionsModalVisible: boolean;
  isAssumptionsModalVisible: boolean;
  loans: CalculatorFederalLoanFormValue[];
  firstChildBirthYear?: number | null;
  secondChildBirthYear?: number | null;
  thirdChildBirthYear?: number | null;
  fourthChildBirthYear?: number | null;
  fifthChildBirthYear?: number | null;
  sixthChildBirthYear?: number | null;
  seventhChildBirthYear?: number | null;
  eighthChildBirthYear?: number | null;
  ninthChildBirthYear?: number | null;
  tenthChildBirthYear?: number | null;
  isItemizedDeductionDisabled: boolean;
  isSpouseItamizedDeductionDisabled: boolean;
  spouseAnnualSalaryDeduction?: number;
  state?: string | null;

  //saved scenarios can have this
  isSimulationForClient?: boolean;
  isSimulationForSpouse?: boolean;
};

const ensureEffectiveIDRMonths = (loans: CalculatorFederalLoanFormValue[]) => {
  const loan = loans.find(
    (x) =>
      x.assignedRepaymentPlanType === 'PAY AS YOU EARN' ||
      x.assignedRepaymentPlanType === 'SAVE' ||
      ((x.repaymentPlanType === 'PAY AS YOU EARN' || x.repaymentPlanType === 'SAVE') &&
        !x.assignedRepaymentPlanType)
  );

  const allIDRMonthsAreZero = loans.every((x) => x.idrMonthsInRepayment === 0);

  return !!loan && allIDRMonthsAreZero;
};

const fixScenarioLoans = (
  values: CalculatorFormValues,
  federalLoans: CalculatorFederalLoanView[],
  privateLoans: CalculatorPrivateLoanFormValue[]
) => {
  const replacePrivateLoans = values.privateLoans.find((x) => !x.amount || !x.type);

  const updatedFederaLoans = federalLoans.map((l) => ({
    ...l,
    graduate: !!l.type?.toLowerCase()?.includes('graduate'),
  })) as CalculatorFederalLoanFormValue[];

  if (replacePrivateLoans) {
    return {
      ...values,
      privateLoans: privateLoans.map((l) => {
        return {
          type: l.type ?? 'OTHER',
          amount: l.amount ?? 0,
          extraPayout: l.extraPayout ?? 0,
          interestRate: l.interestRate,
          monthlyPayment: l.monthlyPayment,
          name: l.name,
          startDate: l.startDate ? dayjs.utc(l.startDate) : null,
        };
      }),
    };
  } else {
    return {
      ...values,
    };
  }
};

export const EditCalculatorDetails = ({
  getPaymentOptions,
  initialData,
  clientName,
  loadedCalculatorScenarios,
}: {
  getPaymentOptions: (input: CalculatorFormValues) => void;
  initialData: CalculatorView;
  loadedCalculatorScenarios: any;
  clientName: string;
}) => {
  const [activeScenario, setActiveScenario] = useState(loadedCalculatorScenarios[0]);
  const [showSalaryWarningModal, setShowSalaryWarningModal] = useState(false);
  const [showEffectiveIDRMonthsWarning, setShowEffectiveIDRMonthsWarning] = useState(false);
  const [ignoreNoSalary, setIgnoreNoSalary] = useState(false);
  const [ignoreEffectiveIDRMonthsIssue, setIgnoreEffectiveIDRMonthsIssue] = useState(false);
  const initialValues = loadedCalculatorScenarios.length
    ? fixScenarioLoans(
        convertScenarioJsonToFormData(activeScenario?.jsonData),
        initialData.loans,
        initialData.privateLoans
      )
    : createInitialValues(initialData);

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={async (v) => {
          const values = {
            ...v,
            annualIncreaseOfMonthlyExpensesPercentage:
              v.annualIncreaseOfMonthlyExpensesPercentage || 0,
            annualSalaryDeduction: v.annualSalaryDeduction || 0,
            monthlyExpenses: v.monthlyExpenses || 0,
            leftoverIncomeToUseTowardsLoans: v.leftoverIncomeToUseTowardsLoans || 0,
            addedExcessCashFromOtherSources: v.addedExcessCashFromOtherSources || 0,
            employerMonthlyContribution: v.employerMonthlyContribution || 0,
            employerContributionLifetimeMaximum: v.employerContributionLifetimeMaximum || 0,
            currentSavingTowardsTaxBomb: v.currentSavingTowardsTaxBomb || 0,
            estimatedMonthlySavingTowardsTaxBomb: v.estimatedMonthlySavingTowardsTaxBomb || 0,
            teacherLoanForgivenessLimit: v.teacherLoanForgivenessLimit || 0,
            itemizedDeductions: v.itemizedDeductions || 0,
            spouseItemizedDeductions: v.spouseItemizedDeductions || 0,
            spouseAnnualSalaryDeduction: v.spouseAnnualSalaryDeduction || 0,
            clientSalary: v.clientSalary || 0,
          };
          if (ensureEffectiveIDRMonths(values.loans) && !ignoreEffectiveIDRMonthsIssue) {
            setShowEffectiveIDRMonthsWarning(true);
            return;
          }

          if (!v.clientSalary && v.clientSalary <= 0 && !ignoreNoSalary) {
            setShowSalaryWarningModal(true);
            return;
          }

          getPaymentOptions({
            ...values,
            isSelfEmployed: !!v.isSelfEmployed,
            makeExtraPaymentsWhenCashFlowAllows: false,
            isPslfActive: !!v.isPslfActive,
            isTeacherForgivnessActive: !!v.isTeacherForgivnessActive,
            isTimeBasedForgivnessActive: !!v.isTimeBasedForgivnessActive,
          });

          setIgnoreNoSalary(false);

          return;
        }}
        validate={(values) => {
          // add checks for negative numebrs function will accept errors object and fieldNames that will iterate over and do the checks
          const addChecksForNegativeNumbers = (
            errors: FormikErrors<CalculatorFormValues>,
            fieldNames: Array<keyof CalculatorFormValues>
          ) => {
            fieldNames.forEach((fieldName) => {
              if (values[fieldName] != undefined && (values[fieldName] as number) < 0) {
                errors[fieldName] = `Cannot be less than 0`;
              }
            });
          };

          const errors: FormikErrors<CalculatorFormValues> = {};

          if (values.spouseAnnualSalary < 0) {
            errors.spouseAnnualSalary = 'Cannot be less than 0';
          }

          const totalLoansBalance = values.loans.reduce(
            (prev, next) => prev + next.interestBalance + next.principalBalance,
            0
          );

          if (values.isTeacherForgivnessActive && totalLoansBalance > 17500) {
            errors.isTeacherForgivnessActive = 'Not applicable for balance higher than $17,500';
            errors.teacherLoanForgivenessLimit = 'Not applicable for balance higher than $17,500';
          }

          if (
            values.isTeacherForgivnessActive &&
            values.teacherLoanForgivenessLimit == 5000 &&
            totalLoansBalance > 5000
          ) {
            errors.teacherLoanForgivenessLimit = 'Not applicable for balance higher than $5,000';
          }

          if (values.annualSalaryDeduction != undefined && values.annualSalaryDeduction > 76500) {
            errors.annualSalaryDeduction = 'Cannot be greater than $76,500';
          }

          if (values.annualSalaryDeduction != undefined && values.annualSalaryDeduction < 0) {
            errors.annualSalaryDeduction = 'Cannot be less than 0';
          }

          if (values.itemizedDeductions != undefined && values.itemizedDeductions < 0) {
            errors.itemizedDeductions = 'Cannot be less than 0';
          }

          if (values.spouseItemizedDeductions != undefined && values.spouseItemizedDeductions < 0) {
            errors.spouseItemizedDeductions = 'Cannot be less than 0';
          }

          if (
            values.spouseAnnualSalaryDeduction != undefined &&
            values.spouseAnnualSalaryDeduction > 76500
          ) {
            errors.spouseAnnualSalaryDeduction = 'Cannot be greater than $76,500';
          }

          if (values.monthlyExpenses != undefined && values.monthlyExpenses < 0) {
            errors.monthlyExpenses = 'Cannot be less than 0';
          }

          if (
            values.leftoverIncomeToUseTowardsLoans != undefined &&
            values.leftoverIncomeToUseTowardsLoans < 0
          ) {
            errors.leftoverIncomeToUseTowardsLoans = 'Cannot be less than 0';
          }

          if (
            values.addedExcessCashFromOtherSources != undefined &&
            values.addedExcessCashFromOtherSources < 0
          ) {
            errors.addedExcessCashFromOtherSources = 'Cannot be less than 0';
          }

          if (
            values.employerMonthlyContribution != undefined &&
            values.employerMonthlyContribution < 0
          ) {
            errors.employerMonthlyContribution = 'Cannot be less than 0';
          }

          if (
            values.currentSavingTowardsTaxBomb != undefined &&
            values.currentSavingTowardsTaxBomb < 0
          ) {
            errors.currentSavingTowardsTaxBomb = 'Cannot be less than 0';
          }

          if (
            values.estimatedMonthlySavingTowardsTaxBomb != undefined &&
            values.estimatedMonthlySavingTowardsTaxBomb < 0
          ) {
            errors.estimatedMonthlySavingTowardsTaxBomb = 'Cannot be less than 0';
          }

          if (
            values.teacherLoanForgivenessLimit != undefined &&
            values.teacherLoanForgivenessLimit < 0
          ) {
            errors.teacherLoanForgivenessLimit = 'Cannot be less than 0';
          }

          if (
            values.spouseAnnualSalaryDeduction != undefined &&
            values.spouseAnnualSalaryDeduction > 76500
          ) {
            errors.spouseAnnualSalaryDeduction = 'Cannot be greater than $76,500';
          }

          if (values.taxFilingType === TaxFilingType.MarriedFilingSeparately) {
            const loan = values.loans.find(
              (x: any) =>
                x.assignedRepaymentPlanType === 'REVISED PAY AS YOU EARN' ||
                (x.repaymentPlanType === 'REVISED PAY AS YOU EARN' && !x.assignedRepaymentPlanType)
            );

            if (loan) {
              errors.loans = `Married Filing Separately shouldn't be run on REPAYE.`;
              errors.taxFilingType = `Married Filing Separately shouldn't be run on REPAYE.`;
            }
          }
          {
            /*
          const isBefore2014 = values.loans.some(
            (x: any) =>
              x.assignedRepaymentPlanType === 'INCOME BASED' &&
              x.repaymentBeginDate &&
              dayjs(x.repaymentBeginDate).isBefore(dayjs('2014-07-01'))
          );
          const isAfterOrEqual2014 = values.loans.some(
            (x: any) =>
              (x.assignedRepaymentPlanType === 'INCOME BASED' &&
                x.repaymentBeginDate &&
                dayjs(x.repaymentBeginDate).isSame(dayjs('2014-07-01'))) ||
              dayjs(x.repaymentBeginDate).isAfter(dayjs('2014-07-01'))
          );

          if (isBefore2014 && isAfterOrEqual2014) {
            values.loans.forEach((loan: any, index: number) => {
              if (loan.assignedRepaymentPlanType === 'INCOME BASED') {
                (errors as any)[`loans[${index}].repaymentBeginDate`] =
                  'All IBR loans should have a repayment start date either before or on/after 07/01/2014.';
              }
            });
          }
            */
          }
          values.loans.forEach((loan: any, index: number) => {
            if (!loan.repaymentBeginDate) {
              (errors as any)[`loans[${index}].repaymentBeginDate`] =
                'Repayment begin date is required';
            }
          });

          const icrLoans = values.loans.filter(
            (x) => x.assignedRepaymentPlanType == 'INCOME CONTINGENT'
          );

          if (icrLoans.length > 1) {
            values.loans.forEach((loan: any, index: number) => {
              if (loan.assignedRepaymentPlanType == 'INCOME CONTINGENT') {
                (errors as any)[`loans[${index}].assignedRepaymentPlanType`] =
                  'You can have only one loan on ICR';
              }
            });
          }

          addChecksForNegativeNumbers(errors, [
            'rateOfReturnOnTaxSavingsBomb',
            'currentSavingTowardsTaxBomb',
            'estimatedMonthlySavingTowardsTaxBomb',
            'addedExcessCashFromOtherSources',
            'leftoverIncomeToUseTowardsLoans',
            'employerMonthlyContribution',
            'monthlyExpenses',
            'annualSalaryDeduction',
          ]);

          if (
            values.employerMonthlyContribution &&
            values.employerContributionLifetimeMaximum &&
            values.employerMonthlyContribution > values.employerContributionLifetimeMaximum
          ) {
            errors.employerContributionLifetimeMaximum = `Cannot be greater than employer contribution lifetime maximum`;
          }

          values.privateLoans.forEach((loan: any, index: number) => {
            if (!loan.startDate) {
              (errors as any)[`privateLoans[${index}].startDate`] =
                'Repayment begin date is required';
            }
          });

          values.privateLoans.forEach((loan: any, index: number) => {
            if (!loan.monthlyPayment) {
              (errors as any)[`privateLoans[${index}].monthlyPayment`] =
                'Monthly payment is required';
            }
          });

          return errors;
        }}
      >
        {({ isSubmitting, submitForm, values }) => {
          return (
            <>
              <Modal show={isSubmitting || showEffectiveIDRMonthsWarning}>
                <Modal.Body>
                  <p className="font-bold text-md mb-4">
                    To be on the selected repayment plan, there must be a payment date established.
                    Loans with Effective IDR Months left at zero will not be counted in the
                    repayment plan.
                  </p>
                  <div className="flex justify-end gap-2">
                    <Button
                      type="button"
                      color="light"
                      onClick={() => {
                        setShowEffectiveIDRMonthsWarning(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIgnoreEffectiveIDRMonthsIssue(true);
                        setShowEffectiveIDRMonthsWarning(false);
                        submitForm();
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal show={(isSubmitting && !!values.clientSalary) || showSalaryWarningModal}>
                <Modal.Body>
                  <p className="font-bold text-md mb-4">
                    You are about to leave the salary field empty or set it to 0. This will result
                    in the client not having any income. Are you sure you want to proceed?
                  </p>
                  <div className="flex justify-end gap-2">
                    <Button
                      type="button"
                      color="light"
                      onClick={() => {
                        setShowSalaryWarningModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIgnoreNoSalary(true);
                        setShowSalaryWarningModal(false);
                        submitForm();
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="flex flex-row gap-4">
                <Scenarios
                  loadedCalculatorScenarios={loadedCalculatorScenarios}
                  initialData={initialData}
                  setActiveScenario={setActiveScenario}
                />
                <div className="flex-grow">
                  <Form className="gap-y-12" id="configure-client-calculator-details">
                    <ClientInformationForm
                      clientName={clientName}
                      spouseName={initialData.spouseName}
                    />

                    <FederalLoanSummaryForm clientName={clientName} initialData={initialData} />

                    <PrivateLoanSummaryForm
                      privateLoans={values.privateLoans}
                      clientName={clientName}
                      creditCardLoans={initialData.creditCards}
                    />
                  </Form>

                  <FormErrorNotification
                    customMessage={
                      'Looks like some data is missing or in conflict. Please take another look at the fields.'
                    }
                  />
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
